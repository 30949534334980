import { forwardRef } from "react";
import { ButtonStyledComponent } from "./button.style";


const Button = forwardRef(
    (
        { isLoading, variant, children, fullWidth, responsive, type = "button", size, ...rest },
        ref
    ) => {
        return (
            <div>
                <ButtonStyledComponent
                    size={size}
                    fullWidth={fullWidth}
                    type={type}
                    variant={variant}
                    responsive={responsive}
                    ref={ref}
                    loading={isLoading}
                    {...rest}
                >
                    {/* <span>{children}</span> */}
                    <span>
                        {isLoading ? (
                            'Loading ...'
                        ) :
                            children
                        }
                    </span>
                </ButtonStyledComponent>
            </div>
        )
    }
)

export { Button }
