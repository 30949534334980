import { forwardRef, useCallback } from "react";
import {
    ErrorP,
    InputFieldWrapperDiv,
    Div,
    InputField,
} from "./input.style";

const Input = forwardRef(
    (
        {
            id,
            name,
            type = "text",
            label,
            required,
            value,
            error,
            fullWidth,
            className,
            onChange,
            isMobile,
            ...remainingProps
        },
        ref
    ) => {
        const errorId = error ? `${id}-error-text` : undefined;

        const handleChange = useCallback(
            (event) => {
                onChange(event);
            },
            [onChange]
        );

        return (
            <Div fullWidth={fullWidth} className={className}>
                <InputFieldWrapperDiv>
                    <InputField
                        id={id}
                        ref={ref}
                        name={name}
                        type={type}
                        value={value}
                        required={required}
                        aria-invalid={!!error}
                        aria-describedby={errorId}
                        onChange={handleChange}
                        error={error}
                        isMobile={isMobile}
                        {...remainingProps}
                    />
                </InputFieldWrapperDiv>
                {error && (
                    <ErrorP
                        initial="pageInitial"
                        animate="pageAnimate"
                        exit="pageExit"
                        variants={{
                            pageInitial: {
                                opacity: 1,
                                x: 0,
                                y: -5,
                            },
                            pageAnimate: {
                                opacity: 1,
                                x: 0,
                                y: 0,
                            },
                            pageExit: {
                                opacity: 0,
                            },
                        }}
                        id={errorId}
                    >
                        {error}
                    </ErrorP>
                )}
            </Div>
        );
    }
);

Input.displayName = "input";

export { Input };
