import { useEffect } from 'react'
import {
    AboutWrapper,
    AboutMainContentWrapper,
    AboutMainContent,
    AboutSectionFlex,
    AboutImageContainer,
    AboutTextContainer
} from '../../styles/about'
import { WhatIDoSection } from '../../components/what-i-do-section/what-i-do-section'

const AboutPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <AboutWrapper>
            <AboutMainContentWrapper>
                <AboutMainContent>
                    <AboutSectionFlex>
                        <AboutImageContainer>
                            <img className="doctor-img2" src="/assets/images/about-img.png" alt="" />
                        </AboutImageContainer>
                        <AboutTextContainer>
                            <h1>My Story.</h1>
                            <div className="what-i-do-text">
                                Dr. Keshinro was born and bred in Lagos, and he is popularly known as Dr. Kesh among his friends and close circle. He attended King’s College Lagos and
                                later proceeded to the Lagos State University College
                                of Medicine, where he bagged a Bachelor’s degree in Medicine and Surgery (M.B, B.S.).
                                <br />
                                <br />
                                Dr. Keshinro is passionate about creating awareness and educating the public on their health. Debunking superstitions and myths on health issues is one of the things he does best.
                                <br />
                                <br />
                                He also loves playing football and table tennis.
                            </div>
                        </AboutTextContainer>
                    </AboutSectionFlex>

                </AboutMainContent>
                <WhatIDoSection />
            </AboutMainContentWrapper>

        </AboutWrapper>
    )
}

export default AboutPage
