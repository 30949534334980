
import { FaTimes } from "react-icons/fa";
import {
    NavLinks,
    MobileNavContainer,
    AnchorNavLinkItem,
    MenuIcon,
    HashedNavLinkItem
} from "./navbar.style";

const MobileNav = ({
    menuActive,
    setNavIconClicked
}) => {
    const handleClick = () => {
        setNavIconClicked(!menuActive);
    };
    return (
        <>
            <MobileNavContainer menuActive={menuActive} className="navbaritems">
                <>
                    <MenuIcon className="times-icon" style={{ textAlign: 'center', padding: '2rem' }} onClick={handleClick}><FaTimes /></MenuIcon>
                    <NavLinks>
                        <HashedNavLinkItem onClick={() => {
                            window.scrollTo(0, 0)
                            handleClick()
                        }} smooth to="/" activeStyle={{ color: '#0041E9' }}>Home</HashedNavLinkItem>
                        <HashedNavLinkItem smooth onClick={handleClick} to="/about" activeStyle={{ color: '#0041E9' }}>About</HashedNavLinkItem>
                        <AnchorNavLinkItem target='_blank' href="http://blog.keshconsultants.com">
                            Blog
                        </AnchorNavLinkItem>
                        {/* <HashedNavLinkItem
                            smooth
                            onClick={handleClick} to="/about#contact"
                            activeStyle={{ color: '#0041E9' }}>Contact Me</HashedNavLinkItem> */}
                        <HashedNavLinkItem
                            onClick={handleClick}
                            to="/#ask"
                            activeStyle={{ color: '#0041E9' }}
                            smooth
                        >
                            Ask Dr Kesh
                        </HashedNavLinkItem>
                    </NavLinks>
                </>
                {/* <div className="bottom-brand">
                    <BrandImage />
                </div> */}
            </MobileNavContainer>

        </>
    );
};

export { MobileNav };
