import { forwardRef, useCallback } from "react";
import { Div, InputFieldWrapperDiv, TextAreaField, ErrorText } from "./textarea.style";

const Textarea = forwardRef(
    (
        {
            id,
            name,
            required,
            value,
            error,
            fullWidth,
            className,
            onChange,
            isMobile,
            ...remainingProps
        }, ref
    ) => {
        const errorId = error ? `${id}-error-text` : undefined;

        const handleChange = useCallback(
            (event) => {
                onChange(event);
            },
            [onChange]
        );


        return (
            <Div fullWidth={fullWidth}>
                <InputFieldWrapperDiv>

                    <TextAreaField
                        id={id}
                        ref={ref}
                        name={name}
                        value={value}
                        required={required}
                        aria-invalid={!!error}
                        aria-describedby={errorId}
                        onChange={handleChange}
                        className={className}
                        error={error}
                        isMobile={isMobile}
                        {...remainingProps}
                    />
                </InputFieldWrapperDiv>
                {error && (
                    <ErrorText
                        initial="pageInitial"
                        animate="pageAnimate"
                        exit="pageExit"
                        variants={{
                            pageInitial: {
                                opacity: 1,
                                x: 0,
                                y: -5,
                            },
                            pageAnimate: {
                                opacity: 1,
                                x: 0,
                                y: 0,
                            },
                            pageExit: {
                                opacity: 0,
                            },
                        }}
                        id={errorId}
                    >
                        {error}
                    </ErrorText>
                )}
            </Div>
        )
    })

export { Textarea }
