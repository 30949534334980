import React from 'react'
import { FooterStyles, FooterUl } from './footer.style'

const Footer = () => {
    return (
        <FooterStyles>
            <h3 className="footer-text">Let’s connect</h3>
            <div>
                <FooterUl>
                    <li><a href="https://twitter.com/KeshConsultants?s=20" target="_blank" rel="noreferrer"><i className="fab fa-twitter fa-2x"></i></a></li>
                    <li><a href="https://www.linkedin.com/company/kesh-consultants" target="_blank" rel="noreferrer"><i className="fab fa-linkedin-in fa-2x"></i></a></li>
                    <li><a href="https://www.facebook.com/drwalekesh" target="_blank" rel="noreferrer"><i className="fab fa-facebook-f fa-2x"></i></a></li>
                    <li><a href="https://instagram.com/keshconsultants?utm_medium=copy_link" target="_blank" rel="noreferrer"><i className="fab fa-instagram fa-2x"></i></a></li>
                </FooterUl>
            </div>
        </FooterStyles>
    )
}

export { Footer }
