import styled from "styled-components";

export const AskMeContainer = styled.div`
    padding: 0 12rem 0 12rem;
    margin-top: 10rem;
    margin-bottom: 10rem;

    @media screen and (max-width: 1190px) {
        padding: 0 3rem;
     }
`;
export const AskMeBox = styled.div`
    background-color: #FFFFFF;
    border-radius: 2rem;
    display: flex;
    justify-content: space-between;
    align-items:center;
    padding: 5.1rem 3.5rem;

    .ask-name-text {
        font-weight: 700;
        font-size: 5.6rem;
        color: #333333;
        
        @media screen and (max-width: 470px) {
            font-size: 3rem;
         }
        
    }

    @media (max-width: 1124px) {
        flex-direction:column;
        justify-content: initial;
        align-items:initial;

        .ask-name-text {
            text-align: center;
        }
    }

    @media screen and (max-width: 470px) {
        padding: 1rem;
     }
`;