import React from 'react'
import { SectionContainer, SectionGrid, WhatIDoImageContainer, WhatIDoTextContainer } from './what-i-do-section.style'

const WhatIDoSection = () => {
    return (
        <SectionContainer>
            <SectionGrid>
                <WhatIDoImageContainer>
                    <img className="doctor-img" src="/assets/images/raster-1.jpg" alt="raster" />
                    <img className="doctor-img2" src="/assets/images/what-i-do-image.jpg" alt="doctor" />
                </WhatIDoImageContainer>
                <WhatIDoTextContainer>
                    <h1>What I do</h1>
                    <div className="what-i-do-text">
                        Dr. Wale Kesh’s blog is one of the few health blogs targeted at non-health professionals. It aims at educating, creating awareness, and sensitizing individuals on their health.
                        <br />
                        <br />
                        I accept questions on health matters through the “Ask Dr. Kesh” column on the blog. And every question asked will be answered in record time.<br />
                        <br />
                        Please note that all answers to health questions received will be posted on the blog for the benefits of others. However, this will be done with your privacy in mind.<br />
                        <br />
                        Finally, all health articles and answers to health questions would be simplified and written in such a way that it is easy to comprehend.
                    </div>
                </WhatIDoTextContainer>
            </SectionGrid>
        </SectionContainer>
    )
}

export { WhatIDoSection }
