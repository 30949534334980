import styled from "styled-components";

export const SectionContainer = styled.div`
    margin-top: 10rem;
    margin-bottom: 12rem;
    // padding: 0 12rem 0 12rem;

    // @media screen and (max-width: 1190px) {
    //     padding: 0 3rem;
    //  }
`;
export const SectionGrid = styled.div`
    display: flex;
    // flex-wrap: wrap;
    // align-items:center;
    justify-content: space-between;
    align-items:center;

    @media (max-width: 1024px) {
        flex-direction: column;
        justify-content: initial;
        align-items:initial;
    }
`;
export const WhatIDoImageContainer = styled.div`
position:relative;
padding-right: 15px;
padding-left: 15px;
width: 42.3rem;

@media (max-width: 1024px) {
    width: 100%;
}

@media screen and (max-width: 1190px) {
    padding: 0;
 }
.doctor-img {
    position: absolute;
    left: -11%;
    z-index: 1;
    
}

.doctor-img2 {
    position: relative;
    z-index: 2;
    width:100%;
    height:auto;
}

// @media only screen and (max-width: 1248px) {
//     .what-i-do-text {
//         font-weight: 500;
//         font-size: 1.8rem;
//         line-height: 2.6rem;
//         color: rgba(51, 51, 51, 0.8);
//         // width: 48.8rem;
//         margin-top: 3rem;
//     }
//  }
`;
export const WhatIDoImage = styled.div`
width: 42.3rem;
height: 53.1rem;
border-radius: 0px 10rem;
background: #0041E9;
margin-top: 4.1rem;
background-image: url("assets/images/what-i-do-image.jpg");
`;
export const WhatIDoTextContainer = styled.div`
width: 48.8rem;
// height: 53.6rem;
margin-top: 4.1rem;

padding-right: 15px;
padding-left: 15px;

@media screen and (max-width: 1190px) {
    padding: 0;
 }

@media (max-width: 1024px) {
    width: 100%;
}

@media (min-width: 992px) {
    // -ms-flex: 0 0 60%;
    // flex: 0 0 60%;
    // max-width: 60%;
}

h1 {
    font-weight: 800;
    font-size: 4rem;
    color: #333333;

    @media screen and (max-width: 470px) {
        font-size: 3rem;
     }
}

.what-i-do-text {
    font-weight: 500;
    font-size: 1.8rem;
    color: rgba(51, 51, 51, 0.8);
    // width: 48.8rem;
    margin-top: 3rem;

    @media screen and (max-width: 470px) {
        font-size: 1.4rem;
     }
}


`;