import { useState } from "react";
import { BrandImage } from "../brand-image/brand-image";
import { FaBars } from "react-icons/fa";
import {
    NavbarContainer,
    NavBarLinks,
    NavLinks,
    HashedNavLinkItem,
    AnchorNavLinkItem,
    MenuIcon,
    BackDrop
} from "./navbar.style";
import { MobileNav } from "./mobile-nav";

const Navbar = () => {
    const [navIconClicked, setNavIconClicked] = useState(false);

    const handleClick = () => {
        setNavIconClicked(!navIconClicked);
    };
    return (
        <>
            <NavbarContainer>
                <BrandImage />
                <MenuIcon onClick={handleClick}><FaBars /></MenuIcon>
                <NavBarLinks menuActive={navIconClicked}>
                    <NavLinks menuActive={navIconClicked}>
                        <HashedNavLinkItem onClick={() => window.scrollTo(0, 0)} smooth to="/" activeStyle={{ color: '#0041E9' }}>Home</HashedNavLinkItem>
                        <HashedNavLinkItem smooth to="/about" activeStyle={{ color: '#0041E9' }}>About</HashedNavLinkItem>
                        <AnchorNavLinkItem target='_blank' href="http://blog.keshconsultants.com">
                            Blog
                        </AnchorNavLinkItem>
                        {/* ss */}
                        <HashedNavLinkItem
                            to="/#ask"
                            activeStyle={{ color: '#0041E9' }}
                            smooth
                        >
                            Ask Dr Kesh
                        </HashedNavLinkItem>
                    </NavLinks>
                </NavBarLinks>
            </NavbarContainer>
            <MobileNav
                menuActive={navIconClicked}
                setNavIconClicked={setNavIconClicked}
            />
            <BackDrop menuActive={navIconClicked} onClick={handleClick} />
        </>
    )
}

export { Navbar }
