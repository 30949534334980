import styled from "styled-components";

export const FooterStyles = styled.footer`
    padding: 7rem;
    display:flex;
    flex-direction: column;
    row-gap: 2.7rem;
    justify-content: center;
    align-items: center;
    background-color: #0A1935;

    .footer-text {
        font-weight: bold;
        font-size: 3.6rem;
        color: #FFFFFF;

        @media screen and (max-width: 385px) {
            font-size: 2.6rem;
         }
    }
`;

export const FooterUl = styled.ul`
    color: #FFFFFF;
    list-style: none;
    display: flex;

    li:not(:last-child) {
        margin-right: 3.7rem;
    }
`;