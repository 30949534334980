import { motion } from "framer-motion";
import styled, { css } from "styled-components";

export const FormContainer = styled.div`
   // flex: 1;
   // margin-left: 20rem
`;


export const Div = styled.div`
   ${({ fullWidth }) => fullWidth && "width: 100%;"}
   position: relative;
   display: inline-block;
   width: 100%;
`;


export const InputFieldWrapperDiv = styled.div`
   display: flex;
   align-items: center;
   width: 100%;

   
`;

export const InputField = styled.input`
    width: 100%;
    border: none;
    outline: none;
    font-size: 1.5rem;
    appearance: none;
    border-radius: 0;
    padding: 0 1rem;
    box-sizing: border-box;
    height: 50px;
    border-radius: 0px;
    border: 1px solid rgb(243 242 242);
    background-color: rgba(0, 65, 233, 0.1);
}

   &::-ms-clear {
    display: none;
}

   &::-ms-expand {
    display: none;
}

   &:-moz-ui-invalid {
    box-shadow: none;
}

   &::placeholder {
    font-family: "Inter";
    font-size: 1.4rem;
    color: #000000;
}

   &:-webkit-autofill,
   &:-webkit-autofill: hover,
   &:-webkit-autofill: focus,
   &:-webkit-autofill:active {
    transition: background-color 5000s;
   -webkit-text-fill-color: #000!important;
}

   &:disabled {
    background-color: hsl(0, 0 %, 95 %);
}
`;
export const InputWrapper = styled.div`
   margin: 2rem 0;
   width: 100%;

   &.grid-2-2 {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 20px;

      @media only screen and (max-width: 826px) {
         display: grid;
         grid-template-columns: repeat(1, 1fr);
         row-gap: 20px;
         width: 100%;
      }
   }
`;
export const ErrorP = styled(motion.p)`
margin: 0.1rem 0 0 0;
color: ${({ theme }) => theme.colors.redError};
font-size: 1.4rem;
letter-spacing:-0.01rem;
font-weight: 700;

   ${({ easyFlow }) =>
      easyFlow &&
      css`
         margin: 0;
         position: absolute;
         left: 65px;
         bottom:-21px;
         font-size: 11.5px;
      `}
`;