import { LandingCarouselContainer, LandingCarouselInner, LandingCarouselCaption, CarouselImages, Overlay } from './carousel.style'
import { Button } from '../button';
import { NavHashLink } from 'react-router-hash-link';


const Carousel = () => {
    return (
        <LandingCarouselContainer>
            <LandingCarouselInner>
                <CarouselImages>
                    <img src="assets/images/carousel-img-1.webp" alt="carousel-1" />
                    <img src="assets/images/carousel-img-2.webp" alt="carousel-2" />
                    <img src="assets/images/carousel-img-3.webp" alt="carousel-3" />
                    <img src="assets/images/carousel-img-4.webp" alt="carousel-4" />
                    <img src="assets/images/carousel-img-5.webp" alt="carousel-5" />
                </CarouselImages>
                <Overlay />
                <LandingCarouselCaption>
                    <h3 className="carousel-company-name">Kesh <br /> Consultants</h3>
                    <p className="carousel-company-motto">Educating and sensitizing you on your health and health - related issues</p>
                    <NavHashLink
                        to="/#ask"
                        smooth
                    >
                        <Button variant="primary">Contact me</Button>
                    </NavHashLink>

                </LandingCarouselCaption>

            </LandingCarouselInner>
            <div id="ask"></div>
        </LandingCarouselContainer>

    )
}

export { Carousel }
