import { useEffect } from 'react'
import { AskMeSection } from '../../components/ask-me-section/ask-me-section'
import { Carousel } from '../../components/carousel/carousel'
import { GeneralNewsLetterSection } from '../../components/general-newsletter-section'


const LandingPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div id="top" style={{ background: "#F7F7F7" }}>
            <Carousel />

            <AskMeSection />
            <GeneralNewsLetterSection />
        </div>
    )
}

export default LandingPage
