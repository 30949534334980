import { GeneralNewsletterSectionBox, GeneralNewsletterSectionContainer, NewsletterSection, NewsletterForm, CircleBackground, ErrorDiv, SuccessDiv } from './general-newsletter-section.style'
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { Input } from '../input'
import { InputWrapper } from '../input/input.style'
import { Button } from '../button';
import MailchimpSubscribe from "react-mailchimp-subscribe";

//Email schema
const schema = yup.object().shape({
    email: yup.string().email().required("Email field is a required field")
});

const CustomForm = ({ status, message, onValidated }) => {

    const {
        handleSubmit,
        register,
        formState: { errors },
        watch,
    } = useForm({ resolver: yupResolver(schema), mode: "onChange" });
    // const formData = JSON.stringify(watch());

    const onSubmit = async (values) => {
        const { email } = values
        onValidated({
            EMAIL: email,
        });
    }

    return (
        <>
            {status === "success" ? (
                <SuccessDiv
                    initial="pageInitial"
                    animate="pageAnimate"
                    exit="pageExit"
                    variants={{
                        pageInitial: {
                            opacity: 0,
                        },
                        pageAnimate: {
                            opacity: 1,
                        },
                        pageExit: {
                            opacity: 0,
                        },
                    }}
                >
                    {message}
                </SuccessDiv>
            ) : (
                <>
                    <NewsletterForm onSubmit={handleSubmit(onSubmit)}>

                        <InputWrapper className="newsletter-input" style={{ margin: 0, width: '20rem' }}>
                            <Input
                                id="email"
                                label="Email"
                                type="text"
                                placeholder="Email"
                                fullWidth
                                {...register("email")}
                                error={errors.email?.message}
                            />
                        </InputWrapper>
                        <Button
                            type="submit"
                            variant="primary"
                            style={{ height: '5rem', width: '20rem' }}
                            className="newsletter-button"
                            responsive
                            disabled={
                                Object.keys(errors).length > 0 || Object.values(watch()).length < 1 || status === "sending"
                            }
                            isLoading={status === "sending"}
                        >
                            Subscribe Now
                        </Button>
                    </NewsletterForm>
                    {status === "error" && (
                        <ErrorDiv
                            initial="pageInitial"
                            animate="pageAnimate"
                            exit="pageExit"
                            variants={{
                                pageInitial: {
                                    opacity: 0,
                                },
                                pageAnimate: {
                                    opacity: 1,
                                },
                                pageExit: {
                                    opacity: 0,
                                },
                            }}
                        >
                            {message.includes("already subscribed to list KeshConsultants") ? "Already subscribed!!" : message}
                        </ErrorDiv>
                    )}
                </>
            )
            }


        </>
    );
};

const GeneralNewsLetterSection = () => {
    const postUrl = `https://keshconsultants.us5.list-manage.com/subscribe/post?u=${process.env.REACT_APP_MAILCHIMP_U}&id=${process.env.REACT_APP_MAILCHIMP_ID}`;

    return (
        <GeneralNewsletterSectionContainer>
            <GeneralNewsletterSectionBox>

                <div className="text-box">
                    <CircleBackground></CircleBackground>
                    <h3>
                        Fully dedicated to your health
                    </h3>
                    <p className="text-box-main-text">Saying “thank you” to your body is one of the most important things you can do, especially when you’re pursuing a life of self-love and wellness. It’s not a habit that comes naturally because society is constantly telling us to change ourselves to fit a socially accepted standard.</p>
                </div>
                <NewsletterSection>
                    <span className="newsletter-text">Subscribe to our Newsletter</span>
                    <p className="newsletter-main-text">Be the first to get our newsletter about health tips, subscribe to get curated content delivered to your mailbox directly</p>
                    <MailchimpSubscribe
                        url={postUrl}
                        render={({ subscribe, status, message }) => (
                            <CustomForm
                                status={status}
                                message={message}
                                onValidated={formData => subscribe(formData)}
                            />
                        )}
                    />

                </NewsletterSection>
            </GeneralNewsletterSectionBox>
        </GeneralNewsletterSectionContainer>
    )
}

export { GeneralNewsLetterSection }
