import { AskMeContainer, AskMeBox } from './ask-me-section.style'
import { useState } from 'react'
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { Input } from '../input'
import { InputWrapper, FormContainer } from '../input/input.style'
import { Textarea } from '../textarea/textarea';
import { Button } from '../button';
import emailjs from 'emailjs-com';
import { ErrorDiv, SuccessDiv } from '../general-newsletter-section/general-newsletter-section.style';

//Question schema
const schema = yup.object().shape({
    fullName: yup.string().required("Full name is a required field"),
    email: yup.string().email().required("Email field is a required field"),
    phoneNumber: yup.string(),
    question: yup.string().required("Pls ask a question..."),

});

const AskMeSection = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const {
        handleSubmit,
        register,
        formState: { errors },
        watch,
        reset
    } = useForm({ resolver: yupResolver(schema), mode: "onChange" });
    // const formData = JSON.stringify(watch());

    const onSubmit = async (values) => {
        setIsLoading(true)
        setErrorMessage(null)
        setSuccessMessage(null)
        emailjs.send(`${process.env.REACT_APP_EMAILJS_SERVICE_ID}`, `${process.env.REACT_APP_EMAILJS_TEMPLATE_ID}`, values, `${process.env.REACT_APP_EMAILJS_USER_ID}`)
            .then((result) => {
                console.log('okay', result.text);
                reset({
                    fullName: '',
                    email: '',
                    phoneNumber: '',
                    question: '',

                })
                setSuccessMessage("Message sent successfully")
                setIsLoading(false)
            }, (error) => {
                setErrorMessage("Message could not be sent. Pls try again later")
                setIsLoading(false)
            });
    }
    return (
        <AskMeContainer >
            <AskMeBox>
                <div className="ask-name-text">
                    <span>Ask Dr Kesh</span>
                </div>
                <FormContainer>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <InputWrapper>
                            <Input
                                id="fullName"
                                label="Full Name"
                                type="text"
                                placeholder="Full Name"
                                fullWidth
                                {...register("fullName")}
                                error={errors.fullName?.message}
                            />
                        </InputWrapper>
                        <InputWrapper className="grid-2-2">
                            <Input
                                id="email"
                                label="Email"
                                type="text"
                                placeholder="Email"
                                fullWidth
                                {...register("email")}
                                error={errors.email?.message}
                            />
                            <Input
                                id="phone"
                                type="text"
                                placeholder="Phone"
                                {...register("phoneNumber")}
                                error={errors.phoneNumber?.message}
                            />
                        </InputWrapper>
                        <InputWrapper>
                            <Textarea
                                id="question"
                                placeholder="Ask your question here"
                                {...register("question")}
                                error={errors.question?.message}
                            />
                        </InputWrapper>
                        <Button
                            type="submit"
                            style={{ marginLeft: 'auto', marginRight: 'auto' }}
                            variant="primary"
                            responsive
                            disabled={
                                Object.keys(errors).length > 0 || Object.values(watch()).length < 1 || isLoading
                            }
                            isLoading={isLoading}
                        >
                            Submit
                        </Button>
                        {successMessage && (
                            <SuccessDiv
                                initial="pageInitial"
                                animate="pageAnimate"
                                exit="pageExit"
                                variants={{
                                    pageInitial: {
                                        opacity: 0,
                                    },
                                    pageAnimate: {
                                        opacity: 1,
                                    },
                                    pageExit: {
                                        opacity: 0,
                                    },
                                }}
                                style={{ textAlign: 'center' }}
                            >
                                {successMessage}
                            </SuccessDiv>
                        )}
                        {errorMessage && (
                            <ErrorDiv
                                initial="pageInitial"
                                animate="pageAnimate"
                                exit="pageExit"
                                variants={{
                                    pageInitial: {
                                        opacity: 0,
                                    },
                                    pageAnimate: {
                                        opacity: 1,
                                    },
                                    pageExit: {
                                        opacity: 0,
                                    },
                                }}
                                style={{ textAlign: 'center' }}
                            >
                                {errorMessage}
                            </ErrorDiv>
                        )}
                    </form>
                </FormContainer>
            </AskMeBox>
        </AskMeContainer>
    )
}

export { AskMeSection }
