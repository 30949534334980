import { motion } from "framer-motion";
import styled, { css } from "styled-components";

export const GeneralNewsletterSectionContainer = styled.div`
    padding: 0 12rem 10rem 12rem;

    @media screen and (max-width: 1190px) {
        padding: 0 3rem 10rem 3rem;
     }
`;
export const CircleBackground = styled.div`
    position: absolute;
    width: 14.8rem;
    height: 14.8rem;
    right: 0;
    top: 0;

    background-color: rgb(0, 65, 233);
    filter: blur(200px);
`;

export const GeneralNewsletterSectionBox = styled.div`
display: flex;
justify-content: space-between;
align-items:center;
// padding: 5.1rem 3.5rem;
// display: grid;
//       grid-template-columns: repeat(2, 1fr);
//       column-gap: 19.8rem;

//       @media only screen and (max-width: 826px) {
//          display: grid;
//          grid-template-columns: repeat(1, 1fr);
//          row-gap: 20px;
//          width: 100%;
//       }

@media only screen and (max-width: 1052px) {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    row-gap: 10rem;
    width: 100%;
    text-align: center;

    .text-box {
        position: relative;
        h3 {
            width: 100% !important;
        }

        
    }

    .text-box-main-text {
        width: 100% !important;
        margin-left: auto;
        margin-right: auto;
    }
 }

.text-box {
    h3 {
        font-weight: 700;
        font-size: 5.1rem;
        line-height: 6.4rem;
        color: #333333;
        width: 40.5rem;
        margin-bottom: 2rem;

        @media only screen and (max-width: 470px) {
            font-size: 3rem;
        }
    }

    .text-box-main-text {
        width: 50.7rem;
        font-size: 1.8rem;
        line-height: 2.6rem;
        color: rgba(51, 51, 51, 0.8);

        @media only screen and (max-width: 470px) {
            font-size: 1.4rem;
        }
    }
}

`;

export const NewsletterSection = styled.div`
    background-color: #FFFFFF;
    border-radius: 2rem;
    padding: 3rem 3.8rem 5.8rem 3.8rem;
    text-align: center;

    @media only screen and (max-width: 1052px){
        // width: 100%;
        margin: 0 auto;
    }

    @media only screen and (max-width: 556px){
        width: 100%;
        margin: 0;
    }

    @media only screen and (max-width: 470px) {
        padding: 1rem;
    }

    .newsletter-text {
        font-weight: 700;
        font-size: 3.4rem;
        line-height: 5rem;
        width: 25.6rem;
        display: inline-block;
        color: #333333;
        @media only screen and (max-width: 556px) {
            width: 100%;
         }
         @media only screen and (max-width: 470px) {
            font-size: 3rem;
        }
    }

    .newsletter-main-text {
        font-size: 1.6rem;
        line-height: 2.4rem;
        color: rgba(51, 51, 51, 0.8);
        margin-top: 3rem;
        margin-bottom: 4rem; 
        margin-left: auto;
        margin-right: auto;
        width: 33.3rem;
        
        @media only screen and (max-width: 556px) {
            width: 100%;
        }

        @media only screen and (max-width: 470px) {
            font-size: 1.4rem;
        }
    }
`;

export const NewsletterForm = styled(motion.form)`
display: grid;
grid-template-columns: repeat(2, 1fr);
column-gap: 20px;

@media only screen and (max-width: 556px) {
   display: grid;
   grid-template-columns: repeat(1, 1fr);
   row-gap: 20px;
   width: 100%;

   .newsletter-input {
    width: 100% !important;
   }
   .newsletter-button {
    width: 100% !important;
   }
}
`;

export const ErrorDiv = styled(motion.div)`
margin: 2rem 0 0 0;
color: ${({ theme }) => theme.colors.redError};
font-size: 1.4rem;
letter-spacing:-0.01rem;
font-weight: 700;

   ${({ easyFlow }) =>
        easyFlow &&
        css`
         margin: 0;
         position: absolute;
         left: 65px;
         bottom:-21px;
         font-size: 11.5px;
      `}
`;

export const SuccessDiv = styled(motion.div)`
margin: 2rem 0 0 0;
color: ${({ theme }) => theme.colors.keshBlue};
font-size: 1.4rem;
letter-spacing:-0.01rem;
font-weight: 700;

   ${({ easyFlow }) =>
        easyFlow &&
        css`
         margin: 0;
         position: absolute;
         left: 65px;
         bottom:-21px;
         font-size: 11.5px;
      `}
`;