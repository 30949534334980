import { Link } from "react-router-dom";
import { NavHashLink } from 'react-router-hash-link';
import styled, { css } from "styled-components";

export const NavbarContainer = styled.nav`
   height: 10rem;
   width: 100%;
   background-color: rgba(255, 255, 255, 0.3);
   backdrop-filter: blur(100px);
   padding: 3.2rem 3rem 3.2rem 12rem;
   display: flex;
   
   align-items: center;
   position: fixed;
   top: 0;
   z-index: 150;

   @media screen and (max-width: 1190px) {
      position: fixed;
      top: 0;
      padding-left: 3rem;
      justify-content: space-between;
   }
   .dropdown {
      position: relative;
   }
   .dropdown-menu {
      visibility: hidden;
   }
   .dropdown:hover .dropdown-menu {
      visibility: visible;
      opacity: 1;
   }
`;

export const NavBarLinks = styled.div`
   display: flex;
   justify-content: flex-start;
   margin-left: 8.2rem;

   @media screen and (max-width: 1190px) {
      display: none;
   }
`;

export const NavLinks = styled.div`
   font-size: 1.5rem;
   display: flex;
   align-items: center;
   padding: 0 15px;

   @media screen and (max-width: 1190px) {
      flex-direction: column;
      align-items: flex-start;
      // margin-bottom: 20px;

      a {
         display: block;
         height: 45px;
         font-size: 1.48rem;
         font-weight: 400;
      }

      ${({ menuActive }) => menuActive && css``}
   }
`;

export const NavLinkItem = styled(Link)`
    text-decoration: none;
    font-weight: 500;
    font-size: 1.4rem;

    &:not(:first-child) {
        margin-left: 4rem;

        @media screen and (max-width: 1190px) {
            margin-left: 0px;
        }
    }

    &.active {
        color: ${({ theme }) => theme.colors?.primary};
        font-weight: 500;
    }
`;

export const AnchorNavLinkItem = styled.a`
    text-decoration: none;
    font-weight: 500;
    font-size: 1.4rem;

    &:not(:first-child) {
        margin-left: 4rem;

        @media screen and (max-width: 1190px) {
            margin-left: 0px;
        }
    }
`;

export const HashedNavLinkItem = styled(NavHashLink)`
   text-decoration: none;
   font-weight: 500;
   font-size: 1.4rem;
   margin-left: 4rem;

   @media screen and (max-width: 1190px) {
      margin-left: 0px;
   }
`;

export const BackDrop = styled.div`
   visibility: hidden;

   @media screen and (max-width: 1190px) {
      width: 100%;
      height: 100vh;
      position: fixed;
      display: block;
      z-index: 129;
      cursor: pointer;
      background: none;
      transition: background 0.2s ease-in;

      ${({ menuActive }) =>
      menuActive &&
      css`
            visibility: visible;
            background: rgba(0, 0, 0, 0.8);
         `}
   }
`;

export const MobileNavContainer = styled.div`
   display: none;

   @media screen and (max-width: 1190px) {
      background: #fff;
      position: fixed;
      height: calc(100vh - 7rem);
      box-shadow: rgb(0 0 0 / 20%) -4px 20px 10px;
      border: 1px solid rgba(101, 100, 100, 0.1);
      z-index: 2147483647;
      overflow: scroll;
      transition: right 0.3s ease-in-out 0s, opacity 0.4s ease-in-out 0.1s;
      opacity: 0;
      right: -310px;
      backface-visibility: hidden;
      width: 300px;
      display: flex;
      flex-direction: column;
      margin: auto;

      .bottom-brand {
         align-self: baseline;
         justify-self: flex-end;
         position: absolute;
         bottom: 0;
         padding: 10px;
      }

      ${({ menuActive }) =>
      menuActive &&
      css`
            right: 0px;
            opacity: 1;
         `}
   }
`;

export const MenuIcon = styled.div`
   color: ${({ theme }) => theme.colors.keshBlue};
   display: none;
   cursor: pointer;
   font-size: 2rem;

   .times-icon {
      text-align: center;
      padding: 4rem;
   }

   @media screen and (max-width: 1190px) {
      display: block;

      .times-icon {
         text-align: center ;
         padding: 4rem;
      }
   }
`;