import { BrowserRouter as Router, Route } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { theme } from "./theme";

import { Navbar } from './components/navbar'
import { Footer } from "./components/footer/footer";
import CustomSwitch from "./components/custom-switch";

// PAGES
import LandingPage from "./pages/landing-page";
import AboutPage from "./pages/about";

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Navbar />
        <CustomSwitch>

          <Route exact path='/' component={LandingPage} />
          <Route exact path='/home' component={LandingPage} />
          <Route exact path='/about' component={AboutPage} />

        </CustomSwitch>
        <Footer />
      </Router>
    </ThemeProvider>

  );
}

export default App;
