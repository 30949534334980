import styled, { css } from "styled-components";

function variantButtonStyles({ variant }) {
   switch (variant) {
      case "grey":
         return css`
            color:  ${({ theme }) => theme.colors.white};
            background-color: #42425f;
            border: 1px solid ${({ theme }) => theme.colors.white};

            &:hover,
            &:active {
               opacity: 0.8;
            }

            &:disabled,
            &:disabled:active,
            &:disabled:hover {
               color: ${({ theme }) => theme.colors.white};
               border-color: ${({ theme }) => theme.colors.white};
               background-color: ${({ theme }) => theme.colors.white};
               cursor: not-allowed;
            }
         `;
      default:
      case "primary":
         return css`
            color:  ${({ theme }) => theme.colors.white};
            background-color: ${({ theme }) => theme.colors.keshBlue};

            &:hover,
            &:active {
               color:  ${({ theme }) => theme.colors.white};
               background-color: ${({ theme }) => theme.colors.keshBlue};
               opacity: 0.8;
            }

            &:disabled,
            &:disabled:active,
            &:disabled:hover {
               color:  ${({ theme }) => theme.colors.white};
               border-color: ${({ theme }) => theme.colors.white};
               background-color: ${({ theme }) => theme.colors.keshBlue};
               cursor: not-allowed;
               opacity: 0.4;
            }
         `;
   }
}

export const ButtonStyledComponent = styled.button`
   position: relative;
   border: 0;
   padding: 0;
   cursor: ${({ loading }) => loading ? "wait" : "pointer"};
   box-sizing: border-box;
   display: inline-block;
   max-width: 100%;
   text-align: center;
   overflow: hidden;
   white-space: nowrap;
   text-overflow: ellipsis;
   font-family: "Inter";
   font-weight: 600;
   font-size: ${({ size }) => (size === "small" ? "1.2rem" : "1.8rem")};
   letter-spacing: 0;
   line-height: 1.6rem;
   text-transform: capitalize;
   transition: all 300ms ease-in-out;
   background-color: ${({ theme }) => theme.colors.keshBlue};
   color:  ${({ theme }) => theme.colors.white};
   display: flex;
   justify-content: center;
   align-items: center;
   padding: ${({ size }) => {
      switch (size) {
         case "small":
            return "1rem 2rem;";
         default:
            return "1rem 2.9rem;";
      }
   }};

   ${({ loading }) =>
      loading &&
      css`
      cursor: wait !important;
         span {
            position: relative;
            z-index: 2;
         }

         ::before {
            content: "";
            position: absolute;
            z-index: 1;
            top: 0;
            left: -100%;
            width: 300%;
            height: 100%;
            background: #0041E9
              repeating-linear-gradient(
              60deg,
                transparent,
                transparent 10px,
                #1c72dd 10px,
                #1c72dd 20px
              );
            
            animation: loading 1s infinite linear;
          }
      `};

   ${({ fullWidth }) =>
      fullWidth &&
      css`
         width: 100%;
      `};
      ${({ responsive }) =>
      responsive &&
      css`
        @media only screen and (max-width: 600px) {
            width: 100%;
            margin-bottom: 20px;
            margin-left: 0px !important;
        }
      `};
    

   ${variantButtonStyles}
`;
