import styled from "styled-components";

export const Overlay = styled.div`
position: absolute; 
bottom: 0; 
background: rgb(0, 0, 0);
background: rgba(0, 0, 0, 0.5); /* Black see-through */
width: 100%;
height: 100%;
`;

export const LandingCarouselContainer = styled.div`
    position: relative;
    // height: 100%;
`;
export const LandingCarouselInner = styled.div`
    position: relative;
    width: 100%;
    overflow: hidden;
    height: 70rem;
`;
export const LandingCarouselCaption = styled.div`
    position: absolute;
    bottom: 17rem;
    top: 22.7rem;
    left: 9%;
    right: 9%;
    z-index: 10;
    color: #fff;
    
    .carousel-company-name {
        // width: 35.4rem;
        font-weight: 700;
        font-size: 5.6rem;
        line-height: 6.4rem;
        color: ${({ theme }) => theme.colors.white};

        @media screen and (max-width: 433px) {
            font-size: 3.6rem;
            line-height: 4.4rem;
        }
    }

    .carousel-company-motto {
        // width: 34.9rem;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 2rem;
        color: rgba(255, 255, 255, 0.8);
        margin-top: 2rem;
        margin-bottom: 3rem;

        @media screen and (max-width: 433px) {
            font-size: 1.2rem;
            margin-top: 1rem;
            margin-bottom: 2rem;
        }
    }
`;

export const CarouselImages = styled.div`
    > img { 
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        color: transparent;
        opacity: 0;
        z-index: 0;
        -webkit-backface-visibility: hidden;
        -webkit-animation: imageAnimation 30s linear infinite 0s;
        -moz-animation: imageAnimation 30s linear infinite 0s;
        -o-animation: imageAnimation 30s linear infinite 0s;
        -ms-animation: imageAnimation 30s linear infinite 0s;
        animation: imageAnimation 30s linear infinite 0s; 

        @media screen and (max-width: 700px) {
            object-fit:cover;
        }
    }

    > img:nth-child(2)  {
        -webkit-animation-delay: 6s;
        -moz-animation-delay: 6s;
        -o-animation-delay: 6s;
        -ms-animation-delay: 6s;
        animation-delay: 6s; 
    }
    > img:nth-child(3) {
        -webkit-animation-delay: 12s;
        -moz-animation-delay: 12s;
        -o-animation-delay: 12s;
        -ms-animation-delay: 12s;
        animation-delay: 12s; 
    }
    > img:nth-child(4) {
        -webkit-animation-delay: 18s;
        -moz-animation-delay: 18s;
        -o-animation-delay: 18s;
        -ms-animation-delay: 18s;
        animation-delay: 18s; 
    }
   > img:nth-child(5) {
        -webkit-animation-delay: 24s;
        -moz-animation-delay: 24s;
        -o-animation-delay: 24s;
        -ms-animation-delay: 24s;
        animation-delay: 24s; 
    }

    // img:nth-of-type(1) {
    //     animation-delay: 4s;
    //   }
    //   img:nth-of-type(2) {
    //     animation-delay: 2s;
    //   }
    //   img:nth-of-type(3) {
    //     animation-delay: 0s;
    //   }
`;